import React from "react";
import "./App.css";
import Background from "./images/bg.jpg";
import logo from "./images/logo.png";
import productImg from "./images/productSupport.png";
import greenBlack from "./images/green-black.jpg";
import productHighlight_1 from "./images/product-highlight3.jpg";
import productHighlight_2 from "./images/product-highlight2.jpg";
import productHighlight_3 from "./images/product-highlight1.jpg";
import servicesHrdBG from "./images/frontpage_headlineBg.jpg";
import footerbg from "./images/greenTransparent.png";
import usaMade from "./images/america.png";
import vshimHeadline from "./images/vshim_product_heading.jpg";
import redProduct from "./images/shims_red.jpg";
import blueProduct from "./images/shims_blue.jpg";
import blackProduct from "./images/shims_black.jpg";

var bgFill = {
  width: "100%",
  height: "auto",
  background: `url(${Background}) center center`,
  backgroundSize: "100% 100%",
  backgroundAttachment: "fixed"
};

var logoStyle = {
  width: "469px",
  height: "127px",
  //background: `url(${logo}) center center`,
  backgroundSize: "100% auto",
  position: "absolute",
  left: "-10px",
  top: "-16px",
  zIndex: "2"
};

function App() {
  return (
    <div className="bgFill" style={bgFill}>
      <div className="App">
        <header className="container" style={{ overflow: "hidden" }}>
          <div class="row">
            <div
              class="col"
              style={{ background: "rgba(0,0,0,.6)", height: "110px" }}
            >
              <img src={logo} class="img-fluid" style={logoStyle} alt="logo" />
              <div
                style={{
                  background: "#152a34",
                  width: "105%",
                  height: "50px",
                  left: "-10px",
                  bottom: "-16px",
                  position: "absolute",
                  zIndex: "0"
                }}
              ></div>
            </div>
          </div>
        </header>
        <section class="container">
          <div class="row" style={{ background: "rgba(0,83,196,0.8)" }}>
            <div
              className="col-12"
              style={{
                minHeight: "50px",
                background: `url(${greenBlack})`,
                backgroundSize: "100%",
                textAlign: "center",
                color: "white",
                padding: "30px",
                fontWeight: "bold",
                fontSize: "24px",
                lineHeight: "30px"
              }}
            >
              Email us at{" "}
              <a style={{ color: "white" }} href="mailto:sales@vshim">
                Sales@vshim.com
              </a>{" "}
              or order by phone 951-294-7819
            </div>
            <div class="col-7" style={{}}>
              <p
                class="col-12"
                style={{
                  margin: "20px 0 ",
                  color: "white",
                  fontSize: "15px",
                  fontWeight: "normal",
                  letterSpacing: "2px"
                }}
              >
                Don’t waste your time and money on different shim sizes and
                shapes. Maximize your profits by using the Patented Vshim with
                Snap-n-Go technology.
              </p>
              <h3
                style={{
                  margin: "20px 0 ",
                  color: "white",
                  fontSize: "25px",
                  fontWeight: "normal",
                  letterSpacing: "2px",
                  paddingLeft: "20px"
                }}
              >
                Industries
              </h3>
              <div style={{ display: "flex", color: "white" }}>
                <ul>
                  <li>Framing</li>
                  <li>Cabinetry</li>
                  <li>Machinery</li>
                  <li>Glazing</li>
                  <li>Windows &amp; Doors</li>
                </ul>
                <ul>
                  <li>Panels</li>
                  <li>Precast / Tilt ups</li>
                  <li>Masonry</li>
                  <li>Industrial</li>
                </ul>
                <ul>
                  <li>Sheet Metal</li>
                  <li>Restoration</li>
                </ul>
              </div>
              <div style={{ display: "flex", color: "white", width: "100%" }}>
                <div
                  class="product-highlight dropshadowLighter col no-padding"
                  style={{
                    background: `url(${greenBlack})`,
                    backgroundRepeat: "repeat-x"
                  }}
                >
                  <span
                    style={{
                      display: "block",
                      height: "25px",
                      fontWeight: "bold",
                      paddingTop: "2px"
                    }}
                  >
                    Commercial
                  </span>
                  <img
                    style={{ width: "100%" }}
                    src={`${productHighlight_1}`}
                  />
                </div>
                <div
                  class="product-highlight dropshadowLighter col no-padding"
                  style={{
                    background: `url(${greenBlack})`,
                    backgroundRepeat: "repeat-x"
                  }}
                >
                  <span
                    style={{
                      display: "block",
                      height: "25px",
                      fontWeight: "bold",
                      paddingTop: "2px"
                    }}
                  >
                    Industrial
                  </span>
                  <img
                    style={{ width: "100%" }}
                    src={`${productHighlight_2}`}
                    alt="product 2"
                  />
                </div>
                <div
                  class="product-highlight dropshadowLighter col no-padding"
                  style={{
                    background: `url(${greenBlack})`,
                    backgroundRepeat: "repeat-x"
                  }}
                >
                  <span
                    style={{
                      display: "block",
                      height: "25px",
                      fontWeight: "bold",
                      paddingTop: "2px"
                    }}
                  >
                    Residential
                  </span>
                  <img
                    style={{ width: "100%" }}
                    src={`${productHighlight_3}`}
                    alt="product 3"
                  />
                </div>
              </div>
            </div>
            <div class="col-5">
              <img class="img-fluid" src={productImg} alt="" />
            </div>
          </div>
        </section>

        <section class="container" style={{ background: "white" }}>
          <div class="row">
            <div class="col-12">
              <img
                src={vshimHeadline}
                class="img-fluid"
                style={{ marginTop: "30px" }}
                alt="vshim header"
              />
              <p style={{ margin: "30px 10px" }}>
                The V-shim© was designed by tradesmen with over 30 years of
                experience. With as many different applications as there are for
                durable shims, you need one that will not only meet the design
                requirements of the project you are working on, but are
                adaptable to different building conditions. The V-Shim© was
                designed for that very reason.
              </p>
              <p style={{ color: "#86b236", fontSize: "24px", margin: "0" }}>
                What makes Vshim Revolutionary ?
              </p>
              <ul class="vhsim_data_list">
                <li>Saves you money </li>
                <li>Crucial time saver on the job</li>
                <li>Customize sizes, as you need.</li>
                <li>Just snap &amp; go!</li>
              </ul>
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">V-Shim Sizes</th>
                    <th scope="col">Shim Size</th>
                    <th scope="col">Per Box</th>
                    <th scope="col">Price per box</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">
                      <img src={blueProduct} alt="Blue Vshim Shim" />
                    </th>
                    <td>Blue - 1/16"</td>
                    <td>525/box</td>
                    <td>$55.95</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <img src={redProduct} alt="Red Vshim Shim" />
                    </th>
                    <td>Red - 1/8"</td>
                    <td>525/box</td>
                    <td>$92.95</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <img src={blackProduct} alt="Black Vshim Shim" />
                    </th>
                    <td>Black - 1/4"</td>
                    <td>525/box</td>
                    <td>$182.95</td>
                  </tr>
                </tbody>
              </table>
              <p
                class="no-padding no-margin"
                style={{
                  color: "#000",
                  fontSize: "24px",
                  fontWeight: "bold",

                  textAlign: "center"
                }}
              >
                Impact testing reports and MSDS sheets provided upon{" "}
                <a href="mailto:sales@vshim.com">request</a>.
              </p>
              <p
                style={{
                  fontSize: "10px",
                  fontStyle: "italic",
                  width: "100%",
                  textAign: "center"
                }}
              >
                * Quantity Pricing available upon request. We are currently
                working on additional sizes to round out our product offerings
                and will update you as they come on line. If you would like us
                to inform you on new product offering contact us today.
              </p>
            </div>
          </div>
        </section>

        <section>
          <div
            class="container"
            style={{
              background: `url(${greenBlack})`,
              backgroundSize: "100%",
              color: "white",
              textAlign: "center",
              padding: "40px",
              fontSize: "24px"
            }}
          >
            <div class="row">
              <div class="col-12">
                For sales email us at
                <a href="mailto:sales@vshim" style={{ color: "white" }}>
                  <strong> Sales@vshim.com </strong>
                </a>
                or order by phone 951-294-7819 <br />
              </div>
            </div>
          </div>
        </section>

        <section
          class="container"
          style={{ background: "white", height: "auto" }}
        >
          <div class="row">
            <div class="col-md-4 no-padding">
              <span
                class="col"
                style={{
                  height: "auto",
                  background: "red",
                  display: "block",
                  textAlign: "center",
                  padding: "5px",
                  background: `url(${servicesHrdBG})`,
                  color: "rgb(21,41,51)"
                }}
              >
                <strong>Vshim saves Time</strong>
              </span>
              <p
                class="h-75"
                style={{
                  background: "white",
                  fontSize: "12px",
                  padding: "20px 20px 30px 20px",
                  margin: "0"
                }}
              >
                The V-shim© was designed by tradesmen with over 30 years of
                experience. With as many different applications as there are for
                durable shims, you need one that will not only meet the design
                requirements of the project you are working on, but is adaptable
                to different building conditions. The V-Shim© was designed to
                SAVE YOUR LABOR!!
              </p>
            </div>
            <div class="col-md-4 no-padding">
              {" "}
              <span
                class="col"
                style={{
                  height: "auto",
                  background: "red",
                  display: "block",
                  textAlign: "center",
                  padding: "5px",
                  background: `url(${servicesHrdBG})`,
                  borderLeft: "1px solid white",
                  color: "rgb(21,41,51)"
                }}
              >
                <strong>Vshim saves Money</strong>
              </span>
              <p
                class="h-75"
                style={{
                  background: "white",
                  fontSize: "12px",
                  padding: "20px 20px 30px 20px",
                  margin: "0"
                }}
              >
                The VShim© is adaptable to most system sizes and applications.
                Snap-n-Go technology allows you to change the shim width or
                length to meet your field needs - and can be done in seconds
                versus minutes. Just snap the V-Shim© where you need to and keep
                your guys working……not cutting!!
              </p>
            </div>
            <div class="col-md-4 no-padding">
              <span
                class="col"
                style={{
                  height: "auto",
                  background: "red",
                  display: "block",
                  textAlign: "center",
                  padding: "5px",
                  background: `url(${servicesHrdBG})`,
                  borderLeft: "1px solid white",
                  color: "rgb(21,41,51)"
                }}
              >
                <strong> Vshim maximizes PROFITS</strong>
              </span>
              <p
                class="h-75"
                style={{
                  height: "auto",
                  background: "white",
                  fontSize: "12px",
                  padding: "20px 20px 30px 20px",
                  margin: "0"
                }}
              >
                Vshim© is not only competitively priced versus other horseshoe
                shims, it allows you to maximize profits in several different
                ways. First, since the Vshim product line is adaptable to any
                system, you only need to carry one type of shim and limit excess
                carryover or dead stock. Second, by using our Snap-n-Go
                technology, you will save labor on each project which adds
                dollars straight to your bottom line!!
              </p>
            </div>
          </div>
        </section>

        <section
          class="container"
          style={{
            background: `url(${footerbg})`,
            minHeight: "100px",
            width: "100%"
          }}
        >
          <div class="row">
            <div class="col">
              <img style={{ margin: "10px" }} src={usaMade} />
            </div>

            <div
              class="col"
              style={{
                color: "white",
                textAlign: "right",
                marginTop: "20px",
                verticalAlign: "bottom"
              }}
            >
              Vshim is a product of Vtech LLC
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default App;
